import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import background from "../../Figures/PRO003_banner.jpg"
import pageLayout from "../pages.module.css"
import ProjectOverview from "../../components/ProjectOverviews/PRO003Overview.js"
import TarmoPrev from "../../components/TarmoPrev"
import ServoRange from "../../Figures/ServoRange.png"
import SteeringServo from "../../Figures/SteeringServo.jpg"
import WheelRotation from "../../Figures/WheelRotatingRange.jpg"



export default function PRO003_ServoRange() {
  return (
      <div>
      <div>
    <Layout>
    </Layout>
    <div  style={{backgroundImage: "url(" + background + ")" ,backgroundSize: "cover", minHeight: "150px",zIndex:"1"}}>
      <div className={pageLayout.text}>
      <h1 style={{color: "white"}}>PRO003 - RC car tweaking</h1>
      <br />
      <h2 style={{color: "white"}}>Servo range optimization</h2>
        </div>
      </div>
      </div>
      <div style={{}}>
      <div className={pageLayout.text}>
        <h2>Intro</h2>
        <p>During the first pandemic lockdown for COVID in Belgium (around March), I was placed on temporary unimployement and bought myself a 3D printer (Creality CR10-S pro). Since I wanted to have a specifict project to work on off the bat, and I was amazed by the performance of the <a href="https://docs.google.com/document/d/1hfrpDU1DQm6QKrioJsxxcMdq8ormYTlwAHaiswDuSHI/edit">Tarmo4</a> I decided to jump right in and go for it. The RC car has a bit of everything: Normal PLA printing, printed gears and printing with flexible materials.
        <br />I am not going into detail about my specific build of the Tarmo4, but if you have any questions or remarks, feel free to reach out.</p>
        <p>Now that I have the car running, let's see if there are possible improvements. I am thinking of increasing the servo range, perhaps get better steering performance and acceleration. Then let's see if I can get some vehicle dynamics and automation on there.</p>
      <h2>Steering servo problem</h2>
      <p>Perhaps It is my old transmitter (bought about 10 years ago for a model airplaine), but I noticed that the servo used for the steering did not rotate a complete 90&#176; as expected. <br />
      A Servo is actuated by a PWM pulse, so I connected the receiver to an ESP8266 and use the <i>pulseIn()</i> command to read the pulses. The results are shown in the following table:</p>
      <table style={{marginRight:"auto", maxWidth:"50%", marginLeft:"auto",whiteSpace: "nowrap"}}>
      <tr><td></td><td><b>- 90&#176;</b></td><td><b>0&#176;</b></td><td><b>+ 90&#176;</b></td></tr>
      <tr><td><b>Normal Servo</b></td><td>1.0 ms</td><td>1.5 ms</td><td>2.0 ms</td></tr>
      <tr><td><b>My Servo</b></td><td>.992 ms</td><td>1.505 ms</td><td>1.945 ms</td></tr>    
      </table>
      <p>Which actually does not look too bad. Looking at these numbers only a 10&#37; (around 9&#176;) shortage in one direction and should actually increase movement in the other. But overwriting the control of the Servo with the ESP, the improved movement is a lot better.
      The figure below shows the difference in movement between original and the one controlled by the ESP:</p>
      <figure>
        <img width="60%"  src={ServoRange} style={{borderRadius: 20, marginLeft: "20%" , marginRight: "20%"}} alt="Servo range control vs transmitter." />
        <figcaption style={{textAlign: "center"}}>Fig.1 - Servo range from transmitter (top) vs. full range from ESP (bottom).</figcaption>
        </figure>
        <p>Below is the small snippit of code to recalculate the servo PWM signal to actuate across teh full range:</p>
        <pre style={{maxHeight:"500px",overflow:"auto", backgroundColor:"#dedede",borderRadius:"1%"}}>
         <code>{` 
#include <Servo.h>       //Servo library 
Servo servo_test;        //initialize a servo object for the connected servo  
            
float SteeringAngle = 0; 
unsigned long ServoPulse = 0;   
int ServoPin_in = 14;
int ServoPin_out = 12;
int ServoRange[] = {992, 1505, 1945}; // measured servo signal range

void setup(){ 
servo_test.attach(ServoPin_out);      // attach the signal pin of servo to pin9 of arduino
pinMode(ServoPin_in,INPUT);
Serial.begin(9600); 
} 
void loop(){ 
ServoPulse = pulseIn(ServoPin_in,HIGH);
if (ServoPulse < ServoRange[1]){
    SteeringAngle = (ServoPulse-ServoRange[0])*(90.0/(ServoRange[1]-ServoRange[0]));
    }else{
    SteeringAngle = 90+(ServoPulse-ServoRange[1])*(90.0/(ServoRange[2]-ServoRange[1]));
    }
servo_test.write(SteeringAngle);
}
         `}</code>
         </pre>
        <h2>Translation to vehicle</h2>
        <p>It definitively looks like the range of the servo is extended, but does this also tranlate into bigger movement of the wheels for rotating the car? the steering linkage in the Tarmo4 is shown in the figure below: </p>
        <figure>
        <img width="60%"  src={SteeringServo} style={{borderRadius: 20, marginLeft: "20%" , marginRight: "20%"}} alt="SteeringServo." />
        <figcaption style={{textAlign: "center"}}>Fig.2 - Steering servo connection to steering rod.</figcaption>
        </figure>
        <p>As you can see, the movement of the horizontal displacement is less for higher rotational movement of the servo. Looking at the actual movement of the wheels the range is indeed increased, but, as expected, less then what you would think looking at the increase in servo range. But I am still happy with the improvement.</p>
        <figure>
        <img width="60%"  src={WheelRotation} style={{borderRadius: 20, marginLeft: "20%" , marginRight: "20%"}} alt="Improved wheel movement." />
        <figcaption style={{textAlign: "center"}}>Fig.3 - Wheel rotation, green: Original - red: improved.</figcaption>
        </figure>
        <p>This improvement proabably won't be possible for all RC vehicles but could be interesting to find out. I'll do the same to the PWM signal of the EM driving the vehicle and see if there are any improvements to be made there. Since the way of working is the same I will not elaborate on that here.</p>
      </div>
      <div className={pageLayout.side}>
        <h2 style={{}}>Project overview:</h2>
      <ProjectOverview></ProjectOverview>
      </div>
      </div>
    </div>
  )
}